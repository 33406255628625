import contact  from '../assets/contact.png';
import logo  from '../assets/logo.png';
import logo01  from '../assets/logo01.png';
import logo02  from '../assets/logo02.png';
import logo03  from '../assets/logo03.png';
import logo04  from '../assets/logo04.png';
import logo05  from '../assets/logo05.png';
import logo06  from '../assets/logo06.png';
import logofooter  from '../assets/logofooter.png';
import services01  from '../assets/services01.png';
import services02  from '../assets/services02.png';
import services03  from '../assets/services03.png';
import services04  from '../assets/services04.png';
import services05  from '../assets/services05.png';
import services06  from '../assets/services06.png';
import team02 from '../assets/team02.png';
import team03 from '../assets/team03.png';
import team04 from '../assets/team04.png';
import team05 from '../assets/team05.png';
import team06 from '../assets/team06.png';
import team01 from '../assets/team01.png';
import image1 from '../assets/image1.jpeg'
import image2 from '../assets/image2.jpeg'
import image3 from '../assets/image3.jpeg'
import image4 from '../assets/image4.jpeg'
import image5 from '../assets/image5.png';
import background from '../assets/background.png';
import member1 from '../assets/member1.jpeg';
import member2 from '../assets/member2.jpeg';
import member3 from '../assets/member3.jpg';

export default{
contact,
logo,
logo01,
logo02,
logo03,
logo04,
logo05,
logo06,
logofooter,
services01,
services02,
services03,
services04,
services05,
services06,
team02,
team03,
team04,
team05,
team06,
team01,
image1,
image2,
image3,
image4,
image5,
background,
member1,
member2,
member3
};