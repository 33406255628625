import React from 'react';
import './Footer.css';
import { data } from '../../constants';

const Footer = () => {
  return (
    <footer>
      <div className="row align-items-center" id="iletisim">
        <div className="col-md-8 col-12 ">
          <ul className="navbar-footer">
            {data.Menu.map((item, index) => (
              <li> <a href="#" >{item.text}</a></li>
            ))}
          </ul>
        </div>
      </div>
      <div className="row">

        <div className="col-md-4 col-12">
          <ul className="info-contact">
            <li> <span>Bizimle İletişime Geçiniz:</span></li>
            <li>Email: info@siberdiplomasi.com</li>
            <li>Adres: Ankara</li>
          </ul>
        </div>

        <div className="rights">
          <p>© 2024 Siber Diplomasi Merkezi</p>
          <p><a href="#" alt="Privacy Policy">Hakkımızda</a></p>

        </div>
      </div>
    </footer>
  )
}

export default Footer