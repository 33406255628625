import React from 'react';
import './AboutUs.css';
import { data } from '../../constants';


const AboutUs = () => {

  return (
    <div className="container" id="hakkımızda">
      <div style={{ marginBottom: 50, marginTop: 50 }}>
        <p style={{ fontWeight: "bold", color: "#680019" }}>KURULUŞUMUZ</p>
        <p>Siber Diplomasi Merkezi (SDM), 2022 yılında Fikri Arda Canpolat başkanlığında kurulmuş,
          kamu, savunma sanayi ve akademiden uzman üyelerden oluşan bir düşünce kuruluşudur.
          Kuruluşumuz, siber diplomasi, siber güvenlik, diplomasi araştırmaları, hukuk ve müzakere,
          güvenlik, terör ve istihbarat araştırmaları gibi alanlarda faaliyetlerini yürütmektedir.</p>
      </div>

      <div style={{ marginBottom: 50 }}>
        <p style={{ fontWeight: "bold", color: "#680019" }}>VİZYON VE MİSYONUMUZ</p>
        <p>Siber Diplomasi Merkezi, siber güvenlik, uluslararası hukuk ve diplomasi alanlarında özgün
          ve stratejik çözümler sunarak, açık, özgür ve istikrarlı bir siber uzay inşa etmeyi
          amaçlamaktadır. Hedefimiz, analiz çalışmaları, bölgesel/genel stratejik raporlar, güvenlik
          raporları, diplomatik kriz yönetimi, stratejik eylem planları ve uluslararası hukuk ve uzlaşı
          konularında özgün ve rasyonel katkılar sağlayarak, siber uzayda diplomasiye yeni bir bakış
          açısı getirmektir.
          Ulusal ve uluslararası çabaları teşvik etmek, etik ve sorumlu davranış normlarının
          geliştirilmesine katkıda bulunmak ve siber uzayı faydalı bir kaynak haline getirmek amacıyla
          çalışmalarımızı aralıksız olarak sürdürmekteyiz. Siber Diplomasinin kavramsal farkındalığını
          yaratmak, kapsam içeriğinde yer alan alt başlıklarda eğitim ve bilgi paylaşım programları
          düzenleyerek siber uzayda diplomasi ve iş birliğinin öncüsü olmayı hedeflemekteyiz.</p>
        <p style={{ fontWeight: "bold", color: "#680019", marginBottom: 50, marginTop: 50}}>Kuruluşumuzun Değerleri:</p>
        <p style={{ fontWeight: 700, alignItems: "center" }}>
          <svg width={15} height={15} viewBox="0 0 10 19" fill="none">
            <path
              d="M2.64761 17.8538C2.25615 18.249 1.61748 18.2487 1.22629 17.8533L0.696241 17.3175C0.310588 16.9277 0.310803 16.3 0.696722 15.9105L6.56235 9.98949C6.94846 9.59974 6.94846 8.97169 6.56235 8.58193L0.697709 2.66195C0.311401 2.27199 0.311631 1.64354 0.698224 1.25387L1.2315 0.716351C1.62296 0.321773 2.26107 0.322127 2.65209 0.717139L9.19626 7.32806C9.71089 7.84731 10 8.55148 10 9.28571C10 10.0199 9.71089 10.7241 9.19626 11.2434L2.64761 17.8538Z"
              fill="#680019"
            />
          </svg>{" Bilimsellik"} </p>
        <p>Araştırmalarımızda bilimsel metodolojiyi ve objektifliği gözetiriz.</p>
      </div>
      <div>
        <p style={{ fontWeight: 700, alignItems: "center" }}>
          <svg width={15} height={15} viewBox="0 0 10 19" fill="none">
            <path
              d="M2.64761 17.8538C2.25615 18.249 1.61748 18.2487 1.22629 17.8533L0.696241 17.3175C0.310588 16.9277 0.310803 16.3 0.696722 15.9105L6.56235 9.98949C6.94846 9.59974 6.94846 8.97169 6.56235 8.58193L0.697709 2.66195C0.311401 2.27199 0.311631 1.64354 0.698224 1.25387L1.2315 0.716351C1.62296 0.321773 2.26107 0.322127 2.65209 0.717139L9.19626 7.32806C9.71089 7.84731 10 8.55148 10 9.28571C10 10.0199 9.71089 10.7241 9.19626 11.2434L2.64761 17.8538Z"
              fill="#680019"
            />
          </svg>{" Bağımsızlık"} </p>
        <p style={{marginBottom: 50 }}>Çalışmalarımızda herhangi bir siyasi veya ideolojik eğilime bağlı kalmayız.</p>
        <p style={{ fontWeight: 700, alignItems: "center" }}>
          <svg width={15} height={15} viewBox="0 0 10 19" fill="none">
            <path
              d="M2.64761 17.8538C2.25615 18.249 1.61748 18.2487 1.22629 17.8533L0.696241 17.3175C0.310588 16.9277 0.310803 16.3 0.696722 15.9105L6.56235 9.98949C6.94846 9.59974 6.94846 8.97169 6.56235 8.58193L0.697709 2.66195C0.311401 2.27199 0.311631 1.64354 0.698224 1.25387L1.2315 0.716351C1.62296 0.321773 2.26107 0.322127 2.65209 0.717139L9.19626 7.32806C9.71089 7.84731 10 8.55148 10 9.28571C10 10.0199 9.71089 10.7241 9.19626 11.2434L2.64761 17.8538Z"
              fill="#680019"
            />
          </svg>{" Şeffaflık"} </p>
        <p style={{marginBottom: 50 }}>Faaliyetlerimiz ve araştırma sonuçlarımız hakkında şeffaf bir şekilde bilgi veririz.</p>

        <p style={{ fontWeight: 700, alignItems: "center" }}>
          <svg width={15} height={15} viewBox="0 0 10 19" fill="none">
            <path
              d="M2.64761 17.8538C2.25615 18.249 1.61748 18.2487 1.22629 17.8533L0.696241 17.3175C0.310588 16.9277 0.310803 16.3 0.696722 15.9105L6.56235 9.98949C6.94846 9.59974 6.94846 8.97169 6.56235 8.58193L0.697709 2.66195C0.311401 2.27199 0.311631 1.64354 0.698224 1.25387L1.2315 0.716351C1.62296 0.321773 2.26107 0.322127 2.65209 0.717139L9.19626 7.32806C9.71089 7.84731 10 8.55148 10 9.28571C10 10.0199 9.71089 10.7241 9.19626 11.2434L2.64761 17.8538Z"
              fill="#680019"
            />
          </svg>{" Etkileşim"} </p>
        <p style={{marginBottom: 50 }}>Farklı disiplinlerden ve sektörlerden uzmanlarla iş birliği yaparak bilgi alışverişini teşvik ederiz.</p>

        <p style={{ fontWeight: 700, alignItems: "center" }}>
          <svg width={15} height={15} viewBox="0 0 10 19" fill="none">
            <path
              d="M2.64761 17.8538C2.25615 18.249 1.61748 18.2487 1.22629 17.8533L0.696241 17.3175C0.310588 16.9277 0.310803 16.3 0.696722 15.9105L6.56235 9.98949C6.94846 9.59974 6.94846 8.97169 6.56235 8.58193L0.697709 2.66195C0.311401 2.27199 0.311631 1.64354 0.698224 1.25387L1.2315 0.716351C1.62296 0.321773 2.26107 0.322127 2.65209 0.717139L9.19626 7.32806C9.71089 7.84731 10 8.55148 10 9.28571C10 10.0199 9.71089 10.7241 9.19626 11.2434L2.64761 17.8538Z"
              fill="#680019"
            />
          </svg>{" Sorumluluk"} </p>
        <p style={{marginBottom: 50 }}>Araştırmalarımızın ve faaliyetlerimizin toplum üzerindeki etkisinin farkındayız ve etik ilkeler doğrultusunda hareket ederiz.</p>
      </div>
      <div style={{ marginBottom: 50 }}>
        <p style={{ fontWeight: "bold", color: "#680019" }}>YÖNETİM KURULUMUZ:</p>
        <p>Siber Diplomasi Merkezi&#39;nin yönetim kurulu, siber güvenlik, diplomasi, hukuk ve teknoloji
          alanlarında deneyimli uzmanlardan oluşmaktadır. Yönetim kurulu üyeleri, merkezin stratejik
          yönetiminden ve genel işleyişinden sorumludur.</p>

      </div>

      <div>
        <div className="row">
          {data.Team.map(({ name, position, info, foto }, index) => (
            <div className="col-lg-4 col-md-6 col-12">
              <div className="card-team" key={index}>
                <div className="card-team-header">
                  <img className="img-fluid" src={foto} style={{  resize: "horizontal" , borderRadius: 100, width: 100, height: 100, zoom: "initial"}} />
                      <p> <span>{name}</span>
                    {position}</p>
                </div>
                <p>{info}</p>
              </div>
            </div>
          ))}

        </div>
  {/*       <p style={{ fontWeight: "bold", marginTop: 50 , color: "#680019"}}>Organizasyon Şemamız:</p>

        <p style={{ color: "red" }}>Şema görsel olarak yer alacaktır. Görselde Kurullar ve Komisyonlara ait alt başlıklar da
          belirtilecektir.</p> */}

        <p>Siber Diplomasi Merkezi, siber uzayın diplomatik süreçleri ve ilişkileri nasıl etkilediğini ve bu
          alandaki gelişmeleri inceleyen bir düşünce kuruluşudur. Merkez, siber güvenlik, diplomasi
          araştırmaları, hukuk ve müzakere, güvenlik, terör ve istihbarat araştırmaları gibi çeşitli
          alanlarda çalışmalar yürütmektedir.</p>
      </div>
    </div>

  )
}

export default AboutUs