import React from 'react';
import './Process.css';
import { data } from '../../constants';
import Carousel from 'react-bootstrap/Carousel';
const styles = { height: 500, width: "100%" };

const Process = () => {
  return (
    <div className="container" id="etkinlikler">
      <div style={{ ...styles }}>
        <Carousel style={{ background: '#680019', width: "100%", height: 500 }}>
          {data.Testimonials.map((item, index) => (
            <Carousel.Item className="carousel-box" interval={3000}>
              <div className="row align-items-center">
                <div className="col-md-6 col-12">
                  <img className="img-fluid" src={item.image} style={{ height: 500, resize: "both" }} />
                </div>
                <div className="col-md-6 col-8 ">
                  <p style={{ color: "whitesmoke" }}>{item.title}</p>
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>

      </div>
    </div>

  )
}

export default Process