import images from './images';

const Menu = [
    {
        text: 'Hakkımızda ',
        link: '/',
         submenu: [
            { text: 'Vizyonumuz', link: '#hakkımızda' },
            { text: 'Misyonumuz', link: '#hakkımızda' }
          ]
    },
    {
        text: 'Çalışma Alanlarımız',
        link: '#calısmaAlanlarımız',
    },
    { text: 'Raporlar ', link: '/'
    },
    {
        text: 'Etkinlikler',
        link: '#etkinlikler',
    },

    {
        text: 'İletişim',
        link: '#iletisim',
    },
    {
        text: '',
        link: '/iletisim',
    }
];


const WorkingProcess = [
    {
        title: 'Consultation',
        description: 'During the initial consultation, we will discuss your business goals and objectives, target audience, and current marketing efforts. This will allow us to understand your needs and tailor our services to best fit your requirements.',
    },
    {
        title: 'Research and Strategy Development',
        description: 'During the initial consultation, we will discuss your business goals and objectives, target audience, and current marketing efforts. This will allow us to understand your needs and tailor our services to best fit your requirements.',
    },
    {
        title: 'Implementation',
        description: 'During the initial consultation, we will discuss your business goals and objectives, target audience, and current marketing efforts. This will allow us to understand your needs and tailor our services to best fit your requirements.',
    },
    {
        title: 'Monitoring and Optimization',
        description: 'During the initial consultation, we will discuss your business goals and objectives, target audience, and current marketing efforts. This will allow us to understand your needs and tailor our services to best fit your requirements.',
    },
    {
        title: 'Reporting and Communication',
        description: 'During the initial consultation, we will discuss your business goals and objectives, target audience, and current marketing efforts. This will allow us to understand your needs and tailor our services to best fit your requirements.',
    },
    {
        title: 'Continual Improvement',
        description: 'During the initial consultation, we will discuss your business goals and objectives, target audience, and current marketing efforts. This will allow us to understand your needs and tailor our services to best fit your requirements.',
    },

];

const Team = [
    {
        name: 'Fikri Arda CANPOLAT',
        position: 'Yön. Kur. Bşk.',
        info: "Fikri Arda CANPOLAT, Savunma Sanayi, Güvenlik ve Siber Diplomasi alanlarında uzmanlaşmış, deneyimli bir liderdir. 9 yılı aşkın süredir bu alanlarda önemli görevler üstlenerek, stratejik ve inovatif çözümler üretmiştir."
        +"2017 yılında Fırat Üniversitesi Metalurji ve Malzeme Mühendisliği’nden diplomasını alan CANPOLAT, 2021 yılında Ufuk Üniversitesinden Stratejik Yönetim Tezi ile MBA Master’ı derecesi ile mezun olmuştur."+
        +"Mesleki kariyerine 2017 yılında STM 'de Proje Yöneticisi olarak başlamıştır. Bu görevdeyken T.C. Cumhurbaşkanlığı, TSK, EGM ve SSB gibi önemli kamu kurum ve kuruluşlarında milli projelerin yürütülmesinde yönetici olarak rol almıştır. CANPOLAT, Proje Yönetimi, Stratejik yönetim, Siber Güvenlik ve Siber Diplomasi alanlarında uzmanlaşmıştır. Görev aldığı projelerde gösterdiği başarıyla, sektörde saygın bir isim haline gelmiştir."
        +"2022 yılında, siber alandaki diplomasi ve iş birliğini geliştirmek amacıyla Siber Diplomasi Merkezi'ni kurmuştur. Merkezin Kurucu ve Yönetim Kurulu Başkanı olarak görev yapan CANPOLAT, siber alandaki uluslararası risklerin azaltılması ve siber barışın sağlanması için çalışmalar yürütmektedir."
        +"CANPOLAT, aktif bir sivil toplum kuruluşu üyesidir. Ayrıca, çeşitli konferanslarda ve panellerde konuşmacı olarak yer almış ve makaleler kaleme almıştır. Fikri Arda CANPOLAT, vizyoner bakış açısı, derin bilgi birikimi ve analitik düşünme yeteneği ile siber diplomasi, güvenlik ve stratejik yönetim alanlarında önemli bir rol oynamaya devam etmektedir.",
        foto: images.member2,
        linkedin: '#',
    },
    {
        name: 'Mehmet Ali BOZKUŞ',
        position: 'Yön. Kur. Bşk. Yrd.',
        info: "Mehmet Ali Bozkuş, 2017 yılında Ondokuz Mayıs Üniversitesi Mühendislik Fakültesi'nden mezun olmuştur. Mezuniyetinin ardından, Uzaktan Algılama Teknolojileri üzerine lisansüstü eğitimine devam etmiştir. Batı Afrika ve Kafkas ülkelerinde dört yılı aşkın süre boyunca İnsansız Hava Aracı (İHA) Teknolojileri, Hava Platformlarında Veri Güvenliği, Bilgi Güvenliği ve Siber Güvenlik alanlarında araştırma ve çalışmalarda bulunmuştur. Bu süreçte, uluslararası projelerde yer alarak önemli deneyimler kazanmıştır. Türkiye'deki kariyerinde, birçok kamu ve savunma sanayi projesinde görev almış olan Bozkuş, bu projelerdeki tecrübelerini akademi ve kamu kurumlarında panel ve seminerlerle paylaşmış, birçok eğitim programına katkıda bulunmuştur. Mehmet Ali Bozkuş halihazırda Siber Diplomasi Merkezi'nin Kurucu Yönetim Kurulu Başkan Yardımcısı olarak görev yapmaktadır. Bu rolde, siber güvenlik stratejileri geliştirme ve uygulama, uluslararası işbirlikleri kurma ve siber diplomasi alanında farkındalığı artırma gibi önemli sorumluluklar üstlenmektedir.",
        foto: images.member3,
        linkedin: '#',
    },
    {
        name: 'Abdullah Enes GÜLAÇTI',
        position: 'Genel Sekreter',
        info: "Abdullah Enes GÜLAÇTI, 2019 yılında Çukurova Üniversitesi Hukuk fakültesinden mezun olup, diğer yandan Çukurova Üniversitesi Uluslararası İlişkiler Ve Siyaset Bilimi fakültesinde lisans eğitimini halen devam ettirmektedir." 
        +"Mesleki kariyerine GÜLAÇTI Hukuk ve Danışmanlık  olarak başlamış ve halen görevini icra etmeye devam etmektedir. Mesleki olarak Uluslararası Hukuk, Bilişim Hukuku, Siber Hukuku,  KVKK alanında hukuki hizmet ve danışmanlık vermekte, ayrıca bu alanlarda eğitimler vermektedir."
        +"2022 yılında, siber alandaki diplomasiyi ve uluslararası hukuk içerisinde siber hukuku geliştirmek amacıyla Siber Diplomasi Merkezi' ni mevcut yönetim kurulu başkan ve başkan yardımcısı ile birlikte kurmuştur. Merkezde kurucu yönetim kurulu üyesi ve Genel Sekreter olarak görev yapmaktadır. Siber Hukuk alanında uluslararası hukuk kapsamında çalıştaylar ve topluluk faaliyetleri düzenleyerek bu alanda merkezin öncü olması adına çalışmalar yürütmektedir.",
        foto: images.member1,
        linkedin: '#',
    }

];

const Testimonials = [
    {
        title: 'Yönetim Kurulu Başkanı Fikri Arda CANPOLAT, MBA, Başkan Yardımcısı Mehmet Ali Bozkuş ve Yönetim Kurulu Üyesi Gülcan ŞAHİN, T.C. Ankara Valisi Sn. Vasip ŞAHİN’i makamında ziyaret ederek, Güvenlik ve Diplomasi konulu görüşme gerçekleştirmiştir.',
        image: images.image1,
    },
    {
        title: 'Terörle Mücadelede Teknolojinin Gücü: Yapay Zeka Destekli Kameralar Fikri Arda CANPOLAT, MBA #SiberDiplomasiMerkezi #YapayZeka #SiberGüvenlik',
        image: images.image2,
    },
    {
        title:'Siber Diplomasi Merkezi olarak, Ankara Üniversitesi Avrupa Topluluğu Araştırma ve Uygulama Merkezi ile "Avrupa Birliği - Türkiye İlişkilerinde Dijitalleşme ve Siber Güvenlik" Çalıştayı gerçekleştirdik. #SiberDiplomasiMerkezi #AnkaraÜniversitesi #ATAUM #SiberGüvenlik #Dijitalleşme',
        image: images.image3,
    },
    {
        title: 'Diplomasi Savaşı ve Müzakere',
        image: images.image5,
    },
    
];
export default { Menu, WorkingProcess, Team, Testimonials };

