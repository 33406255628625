import React from 'react'
import { Headings } from '../../components';
import './Team.css';

const Team = () => {
  return (
    <div>
      <Headings text="Siber Uzayda Diplomasiye Yeni Bir Bakış Açısı" />
    </div>
  )
}

export default Team