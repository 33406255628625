import React from 'react';
import { Headings } from '../../components';
import './Services.css';


const Services = () => {
  return (
    <div id="calısmaAlanlarımız" className="container" >
      <Headings title="Services" text="ÇALIŞMA ALANLARI" />
      <div style={{ marginBottom: 50, marginTop: 50 }}>
        <p style={{ fontWeight: "bold",color: "#680019" }}>1. Siber Diplomasi</p>
        <p>Siber uzayın diplomatik süreçleri ve ilişkileri nasıl etkilediğini ve bu alandaki gelişmeleri
          inceliyoruz. Bu konuda, siber silahların kontrolü, siber güvenlik normlarının geliştirilmesi ve
          siber kriz yönetimi gibi konulara odaklanıyoruz.</p>
      </div>
      <p style={{ fontWeight: 700, alignItems: "center" }}>
        <svg width={15} height={15} viewBox="0 0 10 19" fill="none">
          <path
            d="M2.64761 17.8538C2.25615 18.249 1.61748 18.2487 1.22629 17.8533L0.696241 17.3175C0.310588 16.9277 0.310803 16.3 0.696722 15.9105L6.56235 9.98949C6.94846 9.59974 6.94846 8.97169 6.56235 8.58193L0.697709 2.66195C0.311401 2.27199 0.311631 1.64354 0.698224 1.25387L1.2315 0.716351C1.62296 0.321773 2.26107 0.322127 2.65209 0.717139L9.19626 7.32806C9.71089 7.84731 10 8.55148 10 9.28571C10 10.0199 9.71089 10.7241 9.19626 11.2434L2.64761 17.8538Z"
            fill="#680019"
          />
        </svg>{" Siber Silahlar ve Silahlanma Kontrolü:"} </p>
      <p>Siber silahların türleri, etkileri ve uluslararası
        barış ve güvenlik için oluşturduğu riskleri araştırıyoruz. Siber silahların kontrolüne
        yönelik uluslararası girişimleri ve bu alandaki norm ve düzenlemeleri inceliyoruz.</p>
      <p style={{ fontWeight: 700, alignItems: "center" }}>
        <svg width={15} height={15} viewBox="0 0 10 19" fill="none">
          <path
            d="M2.64761 17.8538C2.25615 18.249 1.61748 18.2487 1.22629 17.8533L0.696241 17.3175C0.310588 16.9277 0.310803 16.3 0.696722 15.9105L6.56235 9.98949C6.94846 9.59974 6.94846 8.97169 6.56235 8.58193L0.697709 2.66195C0.311401 2.27199 0.311631 1.64354 0.698224 1.25387L1.2315 0.716351C1.62296 0.321773 2.26107 0.322127 2.65209 0.717139L9.19626 7.32806C9.71089 7.84731 10 8.55148 10 9.28571C10 10.0199 9.71089 10.7241 9.19626 11.2434L2.64761 17.8538Z"
            fill="#680019"
          />
        </svg>{" Siber Güvenlik Normları ve Diplomasi:"} </p>
      <p>Siber uzayda güvenliği sağlamak için gerekli
        norm ve düzenlemelerin geliştirilmesi için diplomatik çabaları teşvik ediyoruz.
        Uluslararası siber güvenlik anlaşmaları ve protokolleri üzerine araştırmalar yapıyoruz.</p>
      <p style={{ fontWeight: 700, alignItems: "center" }}>
        <svg width={15} height={15} viewBox="0 0 10 19" fill="none">
          <path
            d="M2.64761 17.8538C2.25615 18.249 1.61748 18.2487 1.22629 17.8533L0.696241 17.3175C0.310588 16.9277 0.310803 16.3 0.696722 15.9105L6.56235 9.98949C6.94846 9.59974 6.94846 8.97169 6.56235 8.58193L0.697709 2.66195C0.311401 2.27199 0.311631 1.64354 0.698224 1.25387L1.2315 0.716351C1.62296 0.321773 2.26107 0.322127 2.65209 0.717139L9.19626 7.32806C9.71089 7.84731 10 8.55148 10 9.28571C10 10.0199 9.71089 10.7241 9.19626 11.2434L2.64761 17.8538Z"
            fill="#680019"
          />
        </svg>{" Siber Kriz Yönetimi:"} </p>
      <p>Siber krizlerin önlenmesi, yönetilmesi ve çözümü için stratejiler
        geliştiriyoruz. Siber krizlerin diplomatik boyutunu inceliyoruz ve krizlerin barışçıl ve
        istikrarlı bir şekilde çözülmesi için uluslararası iş birliğini teşvik ediyoruz.</p>

      <div style={{ marginBottom: 50, marginTop: 50 }}>
        <p style={{ fontWeight: "bold", color: "#680019" }}>2. Siber Güvenlik</p>
        <p>Siber tehditlerin ve saldırıların ulusal ve uluslararası güvenliğe olan etkilerini araştırıyor ve
          bu riskleri yönetmeye yönelik çözümler geliştiriyoruz. Siber tehdit aktörlerinin kimler
          olduğunu, hangi yöntemleri kullandıklarını ve bu saldırılara karşı nasıl korunabileceğinizi size
          anlatıyoruz.</p>
      </div>
      <p style={{ fontWeight: 700, alignItems: "center" }}>
        <svg width={15} height={15} viewBox="0 0 10 19" fill="none">
          <path
            d="M2.64761 17.8538C2.25615 18.249 1.61748 18.2487 1.22629 17.8533L0.696241 17.3175C0.310588 16.9277 0.310803 16.3 0.696722 15.9105L6.56235 9.98949C6.94846 9.59974 6.94846 8.97169 6.56235 8.58193L0.697709 2.66195C0.311401 2.27199 0.311631 1.64354 0.698224 1.25387L1.2315 0.716351C1.62296 0.321773 2.26107 0.322127 2.65209 0.717139L9.19626 7.32806C9.71089 7.84731 10 8.55148 10 9.28571C10 10.0199 9.71089 10.7241 9.19626 11.2434L2.64761 17.8538Z"
            fill="#680019"
          />
        </svg>{" Siber Tehdit Aktörleri ve Yöntemleri:"} </p>
      <p>Siber saldırıların arkasındaki aktörleri,
        kullandıkları yöntemleri ve hedefledikleri sektörleri araştırıyoruz. Siber casusluk,
        siber savaş, siber vandalizm ve fidye yazılımı saldırıları gibi farklı siber tehdit türlerini
        inceliyoruz.</p>
      <p style={{ fontWeight: 700, alignItems: "center" }}>
        <svg width={15} height={15} viewBox="0 0 10 19" fill="none">
          <path
            d="M2.64761 17.8538C2.25615 18.249 1.61748 18.2487 1.22629 17.8533L0.696241 17.3175C0.310588 16.9277 0.310803 16.3 0.696722 15.9105L6.56235 9.98949C6.94846 9.59974 6.94846 8.97169 6.56235 8.58193L0.697709 2.66195C0.311401 2.27199 0.311631 1.64354 0.698224 1.25387L1.2315 0.716351C1.62296 0.321773 2.26107 0.322127 2.65209 0.717139L9.19626 7.32806C9.71089 7.84731 10 8.55148 10 9.28571C10 10.0199 9.71089 10.7241 9.19626 11.2434L2.64761 17.8538Z"
            fill="#680019"
          />
        </svg>{" Siber Güvenlik Stratejileri ve Çözümler: "} </p>
      <p>Kurumlar ve bireyler için siber saldırılara
        karşı korunma ve siber riskleri yönetme konusunda stratejiler geliştiriyoruz. Siber
        güvenlik altyapısı, siber eğitim ve farkındalık programları ve incident response (IR)
        planları gibi konularda yol haritası oluşturuyoruz.</p>
      <p style={{ fontWeight: 700, alignItems: "center" }}>
        <svg width={15} height={15} viewBox="0 0 10 19" fill="none">
          <path
            d="M2.64761 17.8538C2.25615 18.249 1.61748 18.2487 1.22629 17.8533L0.696241 17.3175C0.310588 16.9277 0.310803 16.3 0.696722 15.9105L6.56235 9.98949C6.94846 9.59974 6.94846 8.97169 6.56235 8.58193L0.697709 2.66195C0.311401 2.27199 0.311631 1.64354 0.698224 1.25387L1.2315 0.716351C1.62296 0.321773 2.26107 0.322127 2.65209 0.717139L9.19626 7.32806C9.71089 7.84731 10 8.55148 10 9.28571C10 10.0199 9.71089 10.7241 9.19626 11.2434L2.64761 17.8538Z"
            fill="#680019"
          />
        </svg>{" Uluslararası Siber Güvenlik İş Birliği: "} </p>
      <p>Siber güvenlik risklerini küresel ölçekte ele
        almak için uluslararası iş birliğini teşvik ediyoruz. Uluslararası siber güvenlik
        kuruluşları ve girişimleri ile iş birliği yapıyoruz ve siber güvenlik politikaları ve
        stratejileri üzerine bilgi alışverişini ve uzmanlık paylaşımını teşvik ediyoruz.</p>

      <div style={{ marginBottom: 50, marginTop: 50 }}>
        <p style={{ fontWeight: "bold", color: "#680019" }}>3. Diplomasi Araştırmaları</p>
        <p>Siber uzayda diplomatik araçların kullanımını inceliyor, siber diplomasi normlarının
          geliştirilmesine katkıda bulunuyor ve uluslararası iş birliği modelleri oluşturuyoruz. Bu
          çalışmalarımız sayesinde, siber uzayda güvenli ve istikrarlı bir ortamın tesisi için diplomatik
          çözümlerin nasıl geliştirilebileceğini araştırıyoruz.</p>
      </div>
      <p style={{ fontWeight: 700, alignItems: "center" }}>
        <svg width={15} height={15} viewBox="0 0 10 19" fill="none">
          <path
            d="M2.64761 17.8538C2.25615 18.249 1.61748 18.2487 1.22629 17.8533L0.696241 17.3175C0.310588 16.9277 0.310803 16.3 0.696722 15.9105L6.56235 9.98949C6.94846 9.59974 6.94846 8.97169 6.56235 8.58193L0.697709 2.66195C0.311401 2.27199 0.311631 1.64354 0.698224 1.25387L1.2315 0.716351C1.62296 0.321773 2.26107 0.322127 2.65209 0.717139L9.19626 7.32806C9.71089 7.84731 10 8.55148 10 9.28571C10 10.0199 9.71089 10.7241 9.19626 11.2434L2.64761 17.8538Z"
            fill="#680019"
          />
        </svg>{" Siber Diplomasi Araçları:"} </p>
      <p>Siber uzayda diplomasi için kullanılan araçları ve yöntemleri
        inceliyoruz. Siber diplomasi platformları, sanal elçilikler, dijital diplomatlar ve siber
        diyaloglar gibi yeni diplomasi araçlarının potansiyelini değerlendiriyoruz.</p>
      <p style={{ fontWeight: 700, alignItems: "center" }}>
        <svg width={15} height={15} viewBox="0 0 10 19" fill="none">
          <path
            d="M2.64761 17.8538C2.25615 18.249 1.61748 18.2487 1.22629 17.8533L0.696241 17.3175C0.310588 16.9277 0.310803 16.3 0.696722 15.9105L6.56235 9.98949C6.94846 9.59974 6.94846 8.97169 6.56235 8.58193L0.697709 2.66195C0.311401 2.27199 0.311631 1.64354 0.698224 1.25387L1.2315 0.716351C1.62296 0.321773 2.26107 0.322127 2.65209 0.717139L9.19626 7.32806C9.71089 7.84731 10 8.55148 10 9.28571C10 10.0199 9.71089 10.7241 9.19626 11.2434L2.64761 17.8538Z"
            fill="#680019"
          />
        </svg>{" Siber Diplomasi Normları:"} </p>
      <p>Siber uzayda diplomatik ilişkileri düzenleyen norm ve
        ilkelerin geliştirilmesine katkıda bulunuyoruz. Devletlerin siber uzaydaki
        davranışlarını yönlendirecek uluslararası anlaşmalar ve sözleşmelerin oluşturulması
        için çalışmalar yürütüyoruz.</p>
      <p style={{ fontWeight: 700, alignItems: "center" }}>
        <svg width={15} height={15} viewBox="0 0 10 19" fill="none">
          <path
            d="M2.64761 17.8538C2.25615 18.249 1.61748 18.2487 1.22629 17.8533L0.696241 17.3175C0.310588 16.9277 0.310803 16.3 0.696722 15.9105L6.56235 9.98949C6.94846 9.59974 6.94846 8.97169 6.56235 8.58193L0.697709 2.66195C0.311401 2.27199 0.311631 1.64354 0.698224 1.25387L1.2315 0.716351C1.62296 0.321773 2.26107 0.322127 2.65209 0.717139L9.19626 7.32806C9.71089 7.84731 10 8.55148 10 9.28571C10 10.0199 9.71089 10.7241 9.19626 11.2434L2.64761 17.8538Z"
            fill="#680019"
          />
        </svg>{" Uluslararası İş Birliği Modelleri:"} </p>
      <p>Siber uzayın tehditlerine karşı uluslararası iş birliği
        modellerini geliştiriyoruz. Devletler, uluslararası kuruluşlar, özel sektör ve sivil
        toplum arasında iş birliği mekanizmaları oluşturarak siber güvenlik risklerini
        azaltmayı hedefliyoruz.</p>

      <div style={{ marginBottom: 50, marginTop: 50 }}>
        <p style={{ fontWeight: "bold", color: "#680019" }}>4. Hukuk ve Müzakere</p>
        <p>Siber uzayda uluslararası hukukun rolünü inceliyor, siber suçlarla mücadele için hukuki
          çerçeveler geliştiriyor ve siber alandaki anlaşmazlıkların çözümü için müzakere stratejileri
          oluşturuyoruz. Bu stratejiler ile birlikte ülkemizde bilişim hukukunu zenginleştirerek,
          uluslararası hukukta örnek teşkil etme çabasındayız.</p>
      </div>
      <p style={{ fontWeight: 700, alignItems: "center" }}>
        <svg width={15} height={15} viewBox="0 0 10 19" fill="none">
          <path
            d="M2.64761 17.8538C2.25615 18.249 1.61748 18.2487 1.22629 17.8533L0.696241 17.3175C0.310588 16.9277 0.310803 16.3 0.696722 15.9105L6.56235 9.98949C6.94846 9.59974 6.94846 8.97169 6.56235 8.58193L0.697709 2.66195C0.311401 2.27199 0.311631 1.64354 0.698224 1.25387L1.2315 0.716351C1.62296 0.321773 2.26107 0.322127 2.65209 0.717139L9.19626 7.32806C9.71089 7.84731 10 8.55148 10 9.28571C10 10.0199 9.71089 10.7241 9.19626 11.2434L2.64761 17.8538Z"
            fill="#680019"
          />
        </svg>{" Siber Uzayda Uluslararası Hukuk: "} </p>
      <p>Siber uzayda geçerli olan uluslararası hukuk
        kurallarını inceliyor ve bu kuralların siber tehditlere karşı yeterli olup olmadığını
        değerlendiriyoruz. Siber suçların uluslararası takibi ve yaptırım mekanizmaları
        üzerine araştırmalar yapıyoruz.</p>
      <p style={{ fontWeight: 700, alignItems: "center" }}>
        <svg width={15} height={15} viewBox="0 0 10 19" fill="none">
          <path
            d="M2.64761 17.8538C2.25615 18.249 1.61748 18.2487 1.22629 17.8533L0.696241 17.3175C0.310588 16.9277 0.310803 16.3 0.696722 15.9105L6.56235 9.98949C6.94846 9.59974 6.94846 8.97169 6.56235 8.58193L0.697709 2.66195C0.311401 2.27199 0.311631 1.64354 0.698224 1.25387L1.2315 0.716351C1.62296 0.321773 2.26107 0.322127 2.65209 0.717139L9.19626 7.32806C9.71089 7.84731 10 8.55148 10 9.28571C10 10.0199 9.71089 10.7241 9.19626 11.2434L2.64761 17.8538Z"
            fill="#680019"
          />
        </svg>{" Siber Suçlarla Mücadele: "} </p>
      <p>Siber suçların önlenmesi, soruşturulması ve kovuşturulması
        için hukuki çerçeveler geliştiriyoruz. Uluslararası siber suçlarla mücadele anlaşmaları
        ve protokolleri üzerine araştırmalar yapıyoruz.</p>
      <p style={{ fontWeight: 700, alignItems: "center" }}>
        <svg width={15} height={15} viewBox="0 0 10 19" fill="none">
          <path
            d="M2.64761 17.8538C2.25615 18.249 1.61748 18.2487 1.22629 17.8533L0.696241 17.3175C0.310588 16.9277 0.310803 16.3 0.696722 15.9105L6.56235 9.98949C6.94846 9.59974 6.94846 8.97169 6.56235 8.58193L0.697709 2.66195C0.311401 2.27199 0.311631 1.64354 0.698224 1.25387L1.2315 0.716351C1.62296 0.321773 2.26107 0.322127 2.65209 0.717139L9.19626 7.32806C9.71089 7.84731 10 8.55148 10 9.28571C10 10.0199 9.71089 10.7241 9.19626 11.2434L2.64761 17.8538Z"
            fill="#680019"
          />
        </svg>{" Siber Anlaşmazlıkların Çözümü: "} </p>
      <p>Siber alandaki anlaşmazlıkların barışçıl yollarla
        çözümü için müzakere stratejileri geliştiriyoruz. Siber diplomasi araçlarını kullanarak
        uluslararası krizlerin önlenmesi ve yönetilmesi için çalışmalar yürütüyoruz.</p>

      <div style={{ marginBottom: 50, marginTop: 50 }}>
        <p style={{ fontWeight: "bold", color: "#680019" }}>5. Güvenlik, Terör ve İstihbarat Araştırmaları</p>
        <p>Siber uzayın güvenlik risklerini, terör örgütlerinin siber faaliyetlerini ve istihbarat toplama
          yöntemlerini inceleyen araştırmalar yapıyoruz.</p>
      </div>
      <p style={{ fontWeight: 700, alignItems: "center" }}>
        <svg width={15} height={15} viewBox="0 0 10 19" fill="none">
          <path
            d="M2.64761 17.8538C2.25615 18.249 1.61748 18.2487 1.22629 17.8533L0.696241 17.3175C0.310588 16.9277 0.310803 16.3 0.696722 15.9105L6.56235 9.98949C6.94846 9.59974 6.94846 8.97169 6.56235 8.58193L0.697709 2.66195C0.311401 2.27199 0.311631 1.64354 0.698224 1.25387L1.2315 0.716351C1.62296 0.321773 2.26107 0.322127 2.65209 0.717139L9.19626 7.32806C9.71089 7.84731 10 8.55148 10 9.28571C10 10.0199 9.71089 10.7241 9.19626 11.2434L2.64761 17.8538Z"
            fill="#680019"
          />
        </svg>{" Siber Güvenlik Riskleri: "} </p>
      <p>Siber uzayın kritik altyapılara yönelik tehditlerini, siber
        casusluk faaliyetlerini ve bilgi savaşlarını inceliyoruz. Siber saldırıların ulusal güvenliğe
        etkilerini araştırıyor ve ülkemiz için bu riskleri azaltmaya yönelik çözümler
        geliştiriyoruz.</p>
      <p style={{ fontWeight: 700, alignItems: "center" }}>
        <svg width={15} height={15} viewBox="0 0 10 19" fill="none">
          <path
            d="M2.64761 17.8538C2.25615 18.249 1.61748 18.2487 1.22629 17.8533L0.696241 17.3175C0.310588 16.9277 0.310803 16.3 0.696722 15.9105L6.56235 9.98949C6.94846 9.59974 6.94846 8.97169 6.56235 8.58193L0.697709 2.66195C0.311401 2.27199 0.311631 1.64354 0.698224 1.25387L1.2315 0.716351C1.62296 0.321773 2.26107 0.322127 2.65209 0.717139L9.19626 7.32806C9.71089 7.84731 10 8.55148 10 9.28571C10 10.0199 9.71089 10.7241 9.19626 11.2434L2.64761 17.8538Z"
            fill="#680019"
          />
        </svg>{" Terör Örgütleri ve Siber Faaliyetler: "} </p>
      <p>Terör örgütlerinin siber uzayı propaganda,
        finansman, operasyon planlama ve iletişim amaçlı nasıl kullandıklarını araştırıyoruz.
        Terör örgütlerinin siber yeteneklerini ve bu tehditlere karşı alınması gereken
        önlemleri inceliyoruz.</p>
      <p style={{ fontWeight: 700, alignItems: "center" }}>
        <svg width={15} height={15} viewBox="0 0 10 19" fill="none">
          <path
            d="M2.64761 17.8538C2.25615 18.249 1.61748 18.2487 1.22629 17.8533L0.696241 17.3175C0.310588 16.9277 0.310803 16.3 0.696722 15.9105L6.56235 9.98949C6.94846 9.59974 6.94846 8.97169 6.56235 8.58193L0.697709 2.66195C0.311401 2.27199 0.311631 1.64354 0.698224 1.25387L1.2315 0.716351C1.62296 0.321773 2.26107 0.322127 2.65209 0.717139L9.19626 7.32806C9.71089 7.84731 10 8.55148 10 9.28571C10 10.0199 9.71089 10.7241 9.19626 11.2434L2.64761 17.8538Z"
            fill="#680019"
          />
        </svg>{" İstihbarat Toplama ve Siber Uzay: "} </p>
      <p>Siber uzayın istihbarat toplama faaliyetleri için
        nasıl kullanıldığını inceliyoruz. Siber casusluk yöntemlerini ve bu yöntemlere karşı
        korunma stratejilerini araştırıyoruz.</p>


    </div>

  )
}

export default Services