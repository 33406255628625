import React from 'react';
import './Headings.css';

const Headings = ({ text }) => {
    return (
        <div className="headings">
            <h4 style={{color: "#680019", fontWeight:"bold"}}>{text}</h4>
        </div>
    )
}

export default Headings