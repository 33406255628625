import React from 'react';
import { data, images } from '../../constants';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Menu.css';

const Menu = () => {
  return (
    <Container>
      <Navbar.Brand href="#home">
        <img src={images.logo} height={400} width={"80%"} alt="logo" />
      </Navbar.Brand>
      <Nav style={{ background: "#680019", width: "100%", marginTop: -200, justifyContent: "flex-end"}}>
        {data.Menu.map((item, index) => (
          <div key={index} className="nav-item">
            <Nav.Link
              href={item.link}
              style={{ color: "whitesmoke", borderWidth: 0 }}
              onClick={(e) => {
                if (item.submenu) {
                  e.preventDefault();
                }
              }}
              className="nav-link"
            >
              {item.text}
              {item.submenu && (
                <div style={{ marginLeft: 5 }}>
                  <svg
                    width={14}
                    height={8}
                    viewBox="0 0 14 8"
                    fill="none"
                  >
                    <path
                      d="M0.291461 1.8951C0.104288 1.69794 -6.48499e-05 1.43646 -6.48499e-05 1.1646C-6.48499e-05 0.892746 0.104288 0.631262 0.291461 0.434103L0.39146 0.329102C0.490122 0.225133 0.608917 0.142337 0.740608 0.0857578C0.872299 0.0291784 1.01413 0 1.15746 0C1.30079 0 1.44262 0.0291784 1.57431 0.0857578C1.706 0.142337 1.8248 0.225133 1.92346 0.329102L6.23346 4.8691C6.33212 4.97307 6.45092 5.05587 6.58261 5.11245C6.7143 5.16903 6.85613 5.1982 6.99946 5.1982C7.14279 5.1982 7.28462 5.16903 7.41631 5.11245C7.548 5.05587 7.6668 4.97307 7.76546 4.8691L12.0755 0.330102C12.1741 0.225977 12.293 0.143047 12.4248 0.086374C12.5566 0.0297009 12.6985 0.000472724 12.842 0.000472724C12.9854 0.000472724 13.1274 0.0297009 13.2591 0.086374C13.3909 0.143047 13.5098 0.225977 13.6085 0.330102L13.7085 0.438102C13.8953 0.635211 13.9995 0.896485 13.9995 1.1681C13.9995 1.43972 13.8953 1.70099 13.7085 1.8981L8.56046 7.3181C8.36126 7.53296 8.11983 7.70437 7.85131 7.82158C7.58279 7.9388 7.29295 7.99931 6.99996 7.99931C6.70697 7.99931 6.41713 7.9388 6.14861 7.82158C5.88009 7.70437 5.63866 7.53296 5.43946 7.3181L0.291461 1.8951Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
            </Nav.Link>
            {item.submenu && (
              <div className="submenu">
                {item.submenu.map((subitem, subindex) => (
                  <Nav.Link
                    key={subindex}
                    href={subitem.link}
                    style={{ color: "whitesmoke", borderWidth: 0 }}
                  >
                    {subitem.text}
                  </Nav.Link>
                ))}
              </div>
            )}
          </div>
        ))}
      </Nav>
    </Container>
  );
};

export default Menu;
