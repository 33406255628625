import 'bootstrap/dist/css/bootstrap.min.css';
import { Footer, AboutUs, Process, Services, Team } from './container';
import { Menu } from './components';

const App = () => (
  <div style={{ backgroundColor: "#d9d9d9" }}>
    <div className="container" style={{ backgroundColor: "white" }}>
      <Menu />
      <Team />
      <Process />
      <AboutUs />
      <Services />
      <Footer />
    </div>
  </div>
);

export default App;
